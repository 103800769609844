import {createReducer} from '@reduxjs/toolkit'
import {setEnvironment} from '../actions/environment'

export interface EnvironmentState {
  locale: string
  biToken: string
  visitorId: string
  instanceId: string
}

const initialState: EnvironmentState = {
  locale: null,
  biToken: null,
  visitorId: null,
  instanceId: null,
}

export const environment = createReducer(initialState, builder =>
  builder.addCase(setEnvironment, (state, action) => action.payload),
)
